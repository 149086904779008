@import "mixins/navigation";
@import "mixins/common";

.dxbl-treeview {
    --dxbl-treeview-spacing-x: #{$dx-treeview-spacing-x};
    --dxbl-treeview-spacing-y: #{$dx-treeview-spacing-y};
    --dxbl-treeview-font-size: #{$dx-treeview-font-size};
    --dxbl-treeview-font-weight: #{$dx-treeview-font-weight};
    --dxbl-treeview-line-height: #{$dx-navigation-item-line-height};
    --dxbl-treeview-text-padding-y: #{$dx-navigation-item-text-padding-y};
    --dxbl-treeview-btn-bg: #{$dx-treeview-btn-bg};
    --dxbl-treeview-btn-color: #{$dx-treeview-btn-color};
    --dxbl-treeview-btn-hover-bg: #{$dx-treeview-btn-hover-bg};
    --dxbl-treeview-btn-hover-color: #{$dx-treeview-btn-hover-color};
    --dxbl-treeview-image-spacing: #{$dx-treeview-image-spacing};
    --dxbl-treeview-navigation-filter-bottom-spacing: #{$dx-treeview-navigation-filter-bottom-spacing};
    --dxbl-treeview-navigation-filter-content-bg-color: #{$dx-navigation-filter-content-bg-color};
    --dxbl-treeview-navigation-filter-content-color: #{$dx-navigation-filter-content-color};
    --dxbl-treeview-check-all-bottom-spacing: #{$dx-treeview-check-all-bottom-spacing};
    --dxbl-treeview-check-all-border-bottom-color: #{$dx-treeview-check-all-border-color};
    --dxbl-treeview-check-all-border-bottom-width: #{$dx-treeview-check-all-border-bottom-width};
    --dxbl-treeview-check-all-border-bottom-style: #{$dx-treeview-check-all-border-bottom-style};
    --dxbl-treeview-item-text-wrap: #{$dx-treeview-item-text-wrap};
    --dxbl-treeview-item-content-indent: #{$dx-treeview-item-content-indent};
    --dxbl-treeview-item-hover-bg: #{$dx-treeview-item-hover-bg};
    --dxbl-treeview-item-hover-color: #{$dx-treeview-item-hover-color};
    --dxbl-treeview-item-selection-bg: #{$dx-treeview-item-selection-bg};
    --dxbl-treeview-item-selection-color: #{$dx-treeview-item-selection-color};
    --dxbl-treeview-item-border-radius: #{$dx-treeview-item-content-container-border-radius};
    --dxbl-treeview-item-spacing-y: #{$dx-treeview-item-spacing-y};
    --dxbl-treeview-item-content-container-border-width: #{$dx-treeview-item-content-container-border-width};
    --dxbl-treeview-checkbox-checked-color: #{$dx-treeview-item-checkbox-checked-color};
    --dxbl-treeview-checkbox-checked-bg: #{$dx-treeview-checkbox-checked-bg};
    --dxbl-treeview-checkbox-checked-hover-bg: #{$dx-treeview-item-checkbox-checked-hover-bg};
    --dxbl-treeview-checkbox-disabled-bg: #{$dx-treeview-checkbox-disabled-bg};
    --dxbl-treeview-checkbox-disabled-color: #{$dx-treeview-item-checkbox-disabled-color};
    --dxbl-treeview-checkbox-unchecked-bg: #{$dx-treeview-item-checkbox-unchecked-bg};
    --dxbl-treeview-checkbox-unchecked-hover-bg: #{$dx-treeview-item-checkbox-unchecked-hover-bg};
    --dxbl-treeview-checkbox-unchecked-hover-border-color: #{$dx-treeview-item-checkbox-unchecked-hover-border-color};
    --dxbl-treeview-checkbox-unchecked-border-width: #{$dx-treeview-item-checkbox-unchecked-border-width};

    display: flex;
    flex-direction: column;
    padding: var(--dxbl-treeview-spacing-y) var(--dxbl-treeview-spacing-x);

    &.dxbl-sm {
        --dxbl-treeview-font-size: #{$dx-treeview-font-size-sm};
        --dxbl-treeview-line-height: #{$dx-navigation-item-line-height-sm};
        --dxbl-treeview-text-padding-y: #{$dx-navigation-item-text-padding-y-sm};
        --dxbl-treeview-image-spacing: #{$dx-treeview-image-spacing-sm};
        --dxbl-treeview-item-spacing-y: #{$dx-treeview-item-spacing-y-sm};
        --dxbl-treeview-item-content-indent: #{$dx-treeview-item-content-indent-sm};

        .dxbl-treeview-item .dxbl-treeview-item-content .dxbl-checkbox-check-element {
            margin: #{$dx-treeview-item-checkbox-margin-sm};
        }

        &:not(.dxbl-treeview-no-expand-btn) .dxbl-treeview-checkbox-check-all {
            padding-left: #{$dx-treeview-check-all-left-spacing-sm};
        }
    }

    &.dxbl-lg {
        --dxbl-treeview-font-size: #{$dx-treeview-font-size-lg};
        --dxbl-treeview-line-height: #{$dx-navigation-item-line-height-lg};
        --dxbl-treeview-text-padding-y: #{$dx-navigation-item-text-padding-y-lg};
        --dxbl-treeview-image-spacing: #{$dx-treeview-image-spacing-lg};
        --dxbl-treeview-item-spacing-y: #{$dx-treeview-item-spacing-y-lg};
        --dxbl-treeview-item-content-indent: #{$dx-treeview-item-content-indent-lg};

        .dxbl-treeview-item .dxbl-treeview-item-content .dxbl-checkbox-check-element {
            margin: #{$dx-treeview-item-checkbox-margin-lg};
        }

        &:not(.dxbl-treeview-no-expand-btn) .dxbl-treeview-checkbox-check-all {
            padding-left: #{$dx-treeview-check-all-left-spacing-lg};
        }
    }

    & > .dxbl-navigation-filter {
        margin-bottom: var(--dxbl-treeview-navigation-filter-bottom-spacing);
        width: 100%;
    }

    &:not(.dxbl-treeview-no-expand-btn) .dxbl-treeview-checkbox-check-all {
        padding-left: $dx-treeview-check-all-left-spacing;
    }

    @include navigation-filter-mark(var(--dxbl-treeview-navigation-filter-content-bg-color), var(--dxbl-treeview-navigation-filter-content-color));

    & .dxbl-checkbox {
        --dxbl-checkbox-check-element-checked-color: var(--dxbl-treeview-checkbox-checked-color);
        --dxbl-checkbox-check-element-checked-hover-bg: var(--dxbl-treeview-checkbox-checked-hover-bg);
        --dxbl-checkbox-check-element-disabled-color: var(--dxbl-treeview-checkbox-disabled-color);
        --dxbl-checkbox-check-element-unchecked-bg: var(--dxbl-treeview-checkbox-unchecked-bg);
        --dxbl-checkbox-check-element-unchecked-hover-bg: var(--dxbl-treeview-checkbox-unchecked-hover-bg);
        --dxbl-checkbox-check-element-unchecked-border-color: var(--dxbl-treeview-checkbox-unchecked-border-color);
        --dxbl-checkbox-check-element-unchecked-hover-border-color: var(--dxbl-treeview-checkbox-unchecked-hover-border-color);
        --dxbl-checkbox-check-element-unchecked-border-width: var(--dxbl-treeview-checkbox-unchecked-border-width);

        &.dxbl-treeview-checkbox-check-all {
            border-bottom:
                var(--dxbl-treeview-check-all-border-bottom-width) var(--dxbl-treeview-check-all-border-bottom-style)
                var(--dxbl-treeview-check-all-border-bottom-color, $dx-treeview-check-all-border-bottom-color);
            padding-bottom: var(--dxbl-treeview-check-all-bottom-spacing);

            & + * {
                & > .dxbl-scroll-viewer-content > .dxbl-treeview-items-container {
                    padding-top: var(--dxbl-treeview-check-all-bottom-spacing);
                }
            }
        }

        & > .dxbl-checkbox-check-element {
            margin: $dx-treeview-item-checkbox-margin;
        }

        &:not(.dxbl-checkbox-switch):not(.dxbl-checkbox-radio) {
            @include not-disabled-state() {
                @include treeview-checkbox-checked-style("--dxbl-treeview-checkbox-checked-bg", $dx-treeview-item-checkbox-checked-bg, $dx-treeview-item-checked-checkbox-opacity);
            }

            @include disabled-state() {
                @include treeview-checkbox-checked-style("--dxbl-treeview-checkbox-disabled-bg", $dx-treeview-item-checkbox-disabled-bg, $dx-treeview-item-checked-disabled-checkbox-opacity);
            }
        }
    }

    &.dxbl-treeview-text-nowrap {
        --dxbl-treeview-item-text-wrap: nowrap;
    }

    .dxbl-treeview-items-container {
        display: flex;
        flex-flow: column nowrap;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    & > .dxbl-scroll-viewer > .dxbl-scroll-viewer-content > .dxbl-treeview-items-container {
        padding-right: #{$dx-treeview-root-items-container-spacing-x};
    }

    .dxbl-treeview-items-container .dxbl-treeview-items-container .dxbl-treeview-tmpl {
        &,
        & ~ * {
            margin-left: var(--dxbl-treeview-item-content-indent);
        }
    }

    .dxbl-treeview-item {
        @include disabled-state() {
            pointer-events: none;
        }

        & > .dxbl-treeview-item-content {
            position: relative;
            z-index: 0;
            display: flex;
            align-items: center;
            margin-bottom: var(--dxbl-treeview-item-spacing-y);

            & > .dxbl-treeview-item-indent {
                min-width: calc(var(--dxbl-treeview-item-indent, 0) * var(--dxbl-treeview-item-content-indent));
            }

            @include navigation-expand-button-style("--dxbl-treeview-btn-color", null, "--dxbl-treeview-btn-hover-color",
                "--dxbl-treeview-btn-hover-bg", "--dxbl-treeview-btn-hover-color");

            & > .dxbl-treeview-item-container {
                display: flex;
                flex: 1 1 auto;
                align-items: center;
                text-decoration: none;
                color: unset;
                border-radius: var(--dxbl-treeview-item-border-radius);
                font-size: var(--dxbl-treeview-font-size);
                font-weight: var(--dxbl-treeview-font-weight);
                line-height: var(--dxbl-treeview-line-height);
                overflow-x: hidden;

                &:not(.dxbl-disabled):not(.dxbl-active):hover {
                    @include navigation-item-hover-state("--dxbl-treeview-item-hover-bg", "--dxbl-treeview-item-hover-color");
                }

                @include active-state() {
                    @include navigation-item-selected-state("--dxbl-treeview-item-selection-bg", "--dxbl-treeview-item-selection-color");
                }

                @include disabled-state() {
                    opacity: $dx-state-disabled-opacity;
                }

                // focus-state

                & > .dxbl-image {
                    display: inline-block;
                    align-self: center;
                    flex: 0 0 auto;
                    margin-left: var(--dxbl-treeview-image-spacing);
                }

                &:not(.dxbl-disabled):not(.dxbl-active) {
                    cursor: pointer;
                    color: inherit;
                    position: relative;
                    transform: scale(1);
                }


                & > .dxbl-text:not(.dxbl-badge) {
                    --dxbl-text-padding-y: var(--dxbl-treeview-text-padding-y);

                    white-space: var(--dxbl-treeview-item-text-wrap);
                    flex: 1 1 auto;
                }

                .dxbl-treeview-item-text-container {
                    min-width: 0;

                    & > .dxbl-treeview-item-text {
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }
}

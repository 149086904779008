.dxbl-loading-panel {
    --dxbl-loading-panel-underlay-bg: #{$dx-loading-panel-underlay-bg};
    --dxbl-loading-panel-overlay-bg: #{$dx-loading-panel-overlay-bg};
    --dxbl-loading-panel-overlay-opacity: #{$dx-loading-panel-overlay-opacity};
    --dxbl-loading-panel-indicator-size: #{$dx-loading-panel-indicator-size};
    --dxbl-loading-panel-message-size: #{$dx-loading-panel-message-size};
    --dxbl-loading-panel-message-color: #{$dx-wait-indicator-color};
    --dxbl-loading-panel-message-space: #{$dx-loading-panel-message-space};
    --dxbl-loading-panel-indicator-area-padding-x: #{$dx-loading-panel-indicator-area-padding-x};
    --dxbl-loading-panel-indicator-area-padding-y: #{$dx-loading-panel-indicator-area-padding-y};
    --dxbl-loading-panel-indicator-area-margin: #{$dx-loading-panel-indicator-margin};
    --dxbl-loading-panel-indicator-area-height: #{$dx-loading-panel-indicator-area-width};
    --dxbl-loading-panel-indicator-area-width: #{$dx-loading-panel-indicator-area-height};
    --dxbl-loading-panel-indicator-area-background-color: #{$dx-wait-indicator-area-background-color};

    top: 0;
    left: 0;

    &:not([data-qa-dxbl-loaded]) {
        display: none;
    }

    .dxbl-loading-panel-underlay {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: var(--dxbl-loading-panel-underlay-bg);
        pointer-events: none;
        top: 0;
        left: 0;
        opacity: 1;

        & > .dxbl-loading-panel-overlay {
            width: 100%;
            height: 100%;
            pointer-events: none;
            background-color: var(--dxbl-loading-panel-overlay-bg);
            opacity: var(--dxbl-loading-panel-overlay-opacity);
        }

        &.dxbl-loading-panel-content-visible {
            opacity: 0;
        }
    }

    .dxbl-loading-panel-message {
        font-size: var(--dxbl-loading-panel-message-size);
        color: var(--dxbl-loading-panel-message-color);
    }

    .dxbl-loading-panel-target-content {
        position: relative;
        width: 100%;
        height: 100%;

        & + .dxbl-loading-panel-container {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &.dxbl-loading-panel-empty {
        position: static;

        .dxbl-loading-panel-container {
            position: static;
        }
    }

    .dxbl-loading-panel-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        overflow: hidden;
        justify-content: center;
        align-items: center;

        &.dxbl-loading-panel-unblocked {
            pointer-events: none;
        }

        &.dxbl-loading-panel-shading {
            background-color: #{$dx-loading-panel-shading-color};

            .dxbl-loading-panel-indicator-area.dxbl-loading-panel-hidden {
                --dxbl-loading-panel-message-color: #{$dx-wait-indicator-no-area-color};

                .dxbl-wait-indicator {
                    --dxbl-wait-indicator-color: #{$dx-wait-indicator-no-area-color};
                }
            }
        }

        .dxbl-loading-panel-indicator-area {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: var(--dxbl-loading-panel-indicator-area-padding-y) var(--dxbl-loading-panel-indicator-area-padding-x);
            height: var(--dxbl-loading-panel-indicator-area-height);
            width: var(--dxbl-loading-panel-indicator-area-width);
            box-shadow: 0 4px 6px -1px rgba(0 0 0 / 10%), 0 2px 4px -2px rgba(0 0 0 / 10%);
            background: var(--dxbl-loading-panel-indicator-area-background-color);
            border-radius: 999px;
            margin: var(--dxbl-loading-panel-indicator-area-margin);

            &.dxbl-loading-panel-hidden {
                border: none;
                box-shadow: none;
                background: none;
            }
        }
    }

    .dxbl-wait-indicator {
        --dxbl-wait-indicator-size: var(--dxbl-loading-panel-indicator-size);

        order: 2;

        & + .dxbl-loading-panel-message {
            order: 3;
            margin-left: var(--dxbl-loading-panel-message-space);

            &:empty {
                margin-left: 0;
            }

            &.dxbl-loading-panel-message-left {
                margin-left: 0;
                margin-right: var(--dxbl-loading-panel-message-space);
                order: 1;

                &:empty {
                    margin-right: 0;
                }
            }
        }
    }

    &.dxbl-sm {
        --dxbl-loading-panel-message-space: #{$dx-loading-panel-message-space-sm};

        .dxbl-loading-panel-message {
            --dxbl-loading-panel-message-size: #{$dx-loading-panel-message-size-sm};
        }

        .dxbl-loading-panel-indicator-area {
            --dxbl-loading-panel-indicator-area-padding-x: #{$dx-loading-panel-indicator-area-padding-x-sm};
        }
    }

    &.dxbl-lg {
        --dxbl-loading-panel-message-space: #{$dx-loading-panel-message-space-lg};

        .dxbl-loading-panel-message {
            --dxbl-loading-panel-message-size: #{$dx-loading-panel-message-size-lg};
        }

        .dxbl-loading-panel-indicator-area {
            --dxbl-loading-panel-indicator-area-padding-x: #{$dx-loading-panel-indicator-area-padding-x-lg};
        }
    }
}

.dxbl-wait-indicator {
    --dxbl-wait-indicator-color: #{$dx-wait-indicator-color};
    --dxbl-wait-indicator-size: #{$dx-wait-indicator-size};
    --dxbl-wait-indicator-flip-animation-rotation-y: #{$dx-wait-indicator-flip-animation-rotation-y};
    --dxbl-wait-indicator-flip-animation-rotation-x: #{$dx-wait-indicator-flip-animation-rotation-x};

    display: inline-block;
    height: var(--dxbl-wait-indicator-size);
    width: var(--dxbl-wait-indicator-size);
    min-width: var(--dxbl-wait-indicator-size);


    & > svg {
        vertical-align: unset;
    }

    &.dxbl-sm {
        --dxbl-wait-indicator-size: #{$dx-wait-indicator-size-sm};

        &.dxbl-loading-panel-indicator {
            --dxbl-wait-indicator-size: #{$dx-loading-panel-indicator-size-sm};
        }
    }

    &.dxbl-lg {
        --dxbl-wait-indicator-size: #{$dx-wait-indicator-size-lg};

        &.dxbl-loading-panel-indicator {
            --dxbl-wait-indicator-size: #{$dx-loading-panel-indicator-size-lg};
        }
    }

    &.dxbl-wait-indicator-spin {
        transform-origin: center;
        overflow: hidden;
        animation: dxbl-indicator-animation-spin 0.75s infinite linear;

        & > svg > g {
            & > g {
                stroke: var(--dxbl-wait-indicator-color);
            }

            & > circle {
                fill: transparent;
                stroke: var(--dxbl-wait-indicator-color);
                opacity: 0.3;
            }
        }
    }

    &.dxbl-wait-indicator-pulse {
        transform-origin: center;
        fill: var(--dxbl-wait-indicator-color);
        animation: dxbl-indicator-animation-pulse 1.2s cubic-bezier(0.52, 0.6, 0.25, 0.99) infinite;
    }

    &.dxbl-wait-indicator-flip {
        fill: var(--dxbl-wait-indicator-color);
        animation: var(--dxbl-wait-indicator-flip-animation-rotation-y) 1.4s infinite ease-in-out;
    }

    @keyframes dxbl-indicator-animation-spin {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dxbl-indicator-animation-pulse {
        0% {
            transform: scale(0);
            opacity: 1;
        }

        100% {
            transform: scale(1);
            opacity: 0;
        }
    }

    @keyframes dxbl-indicator-animation-flip-rotation-y {
        0% {
            transform: perspective(120px) rotateY(0deg);
        }

        100% {
            transform: perspective(120px) rotateY(360deg);
        }
    }

    @keyframes dxbl-indicator-animation-flip-rotation-x {
        0% {
            transform: perspective(120px) rotateX(0deg);
        }

        100% {
            transform: perspective(120px) rotateX(360deg);
        }
    }
}
